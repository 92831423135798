function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from "react";
// ========= TYPES =========

const logoState = ([`FULL`, `SMALL`] as const);
export type LogoState = (typeof logoState)[number];

// ========= COMPONENT =========

export type Props = {
  /** The size variant of the logo. */
  size?: LogoState;
  /** Should the hover color change be disabled? */
  disableHover?: boolean;
};

/**
 * The standard Alcohol101+ application logo.
 *
 * @param props
 * @returns A React component.
 */
var _ref = process.env.NODE_ENV === "production" ? {
  name: "bjn8wh",
  styles: "position:relative"
} : {
  name: "792hik-Logo",
  styles: "position:relative;label:Logo;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const Logo = ({
  size = `FULL`,
  disableHover = false
}: Props) => {
  const normallyWhite = [!disableHover && {
    ".group:hover &": {
      "color": "var(--color-accent)"
    }
  }, {
    "fill": "currentColor",
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  }, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms"
  }];
  const normallyAccent = [!disableHover && {
    ".group:hover &": {
      "--tw-text-opacity": "1",
      "color": "rgb(255 255 255 / var(--tw-text-opacity))"
    }
  }, {
    "fill": "currentColor",
    "color": "var(--color-accent)"
  }, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms"
  }];
  if (size === `FULL`) return <div css={_ref}>
				<svg width="146" height="27" viewBox="0 0 146 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="group" role="img" aria-labelledby="A101-logo">
					<title id="A101-logo">Alcohol101+ home</title>
					{/* A */}
					<path d="M14.2637 4.77295H7.36413L0 26.7665H6.33602L7.46318 22.7346H14.1971L15.2918 26.7665H21.5612L14.2637 4.77295ZM8.6911 18.2687L10.1496 13.0032C10.5253 11.5603 10.7354 10.6595 10.7798 10.3044H10.913C11.0018 10.9049 11.1999 11.7936 11.5107 12.9706L12.9692 18.2687H8.6911Z" css={normallyAccent} />
					{/* L */}
					<path d="M29.4235 4.77295H23.2207V26.7665H38.4118V21.7001H29.4235V4.77295Z" css={normallyAccent} />
					{/* C */}
					<path d="M52.8827 19.9518C52.8503 19.993 52.8281 20.0445 52.7939 20.0839C51.9195 21.1168 50.7872 21.6332 49.3937 21.6332C48.0001 21.6332 46.8661 21.1168 45.9934 20.0839C45.1207 19.0511 44.6835 17.6013 44.6835 15.7346C44.6835 13.8903 45.1207 12.4525 45.9934 11.4196C46.8678 10.3868 48.0001 9.87037 49.3937 9.87037C50.7872 9.87037 51.9195 10.3868 52.7939 11.4196C52.8861 11.5277 52.9528 11.6581 53.0347 11.7765L58.387 8.82036C58.0711 8.37599 57.7295 7.94878 57.3384 7.55417C55.3147 5.52107 52.6675 4.50537 49.3937 4.50537C46.1198 4.50537 43.4726 5.52278 41.4489 7.55417C39.4251 9.58728 38.4141 12.3135 38.4141 15.7364C38.4141 19.1798 39.4251 21.918 41.4489 23.9511C43.4726 25.9842 46.1198 26.9999 49.3937 26.9999C52.6658 26.9999 55.3147 25.9842 57.3384 23.9511C57.7159 23.572 58.0455 23.1602 58.3512 22.733L52.8827 19.9518Z" css={normallyAccent} />
					{/* O */}
					<path d="M77.6041 7.55417C75.5803 5.52107 72.9332 4.50537 69.6593 4.50537C66.3854 4.50537 63.7383 5.52278 61.7145 7.55417C59.6907 9.58728 58.6797 12.3135 58.6797 15.7364C58.6797 19.1798 59.6907 21.918 61.7145 23.9511C63.7383 25.9842 66.3854 26.9999 69.6593 26.9999C72.9315 26.9999 75.5803 25.9842 77.6041 23.9511C79.6278 21.918 80.6389 19.1798 80.6389 15.7364C80.6406 12.3152 79.6278 9.58728 77.6041 7.55417ZM73.4848 20.0839C72.5011 21.1168 71.2271 21.6332 69.661 21.6332C68.0932 21.6332 66.8192 21.1168 65.8355 20.0839C64.8535 19.0511 64.3616 17.6013 64.3616 15.7346C64.3616 13.8903 64.8535 12.4525 65.8355 11.4196C66.8192 10.3868 68.0932 9.87037 69.661 9.87037C71.2271 9.87037 72.5028 10.3868 73.4848 11.4196C74.4668 12.4525 74.9587 13.8903 74.9587 15.7346C74.9587 17.6013 74.4668 19.0511 73.4848 20.0839Z" css={normallyAccent} />
					{/* H */}
					<path d="M94.479 13.2365H88.8415V4.77295H82.6387V26.7665H88.8415V18.3013H94.479V13.2365Z" css={normallyAccent} />
					{/* 1 */}
					<path d="M98.2084 4.64949V4.6392L94.4785 5.63431V10.8552L96.3383 10.3594V10.6991V13.2383V18.303V19.6396V26.7666H96.3503H98.2101H102.543V3.50684L98.2084 4.64949Z" css={normallyWhite} />
					{/* 0 */}
					<path d="M123.778 7.55417C121.754 5.52107 119.107 4.50537 115.833 4.50537C112.559 4.50537 109.912 5.52278 107.888 7.55417C105.865 9.58728 104.854 12.3135 104.854 15.7364C104.854 19.1798 105.865 21.918 107.888 23.9511C109.912 25.9842 112.559 26.9999 115.833 26.9999C119.105 26.9999 121.754 25.9842 123.778 23.9511C125.802 21.918 126.813 19.1798 126.813 15.7364C126.813 12.3152 125.802 9.58728 123.778 7.55417ZM121.052 15.7364C121.052 17.6013 120.56 19.0511 119.578 20.0857C118.595 21.1185 117.321 21.6349 115.755 21.6349C114.187 21.6349 112.913 21.1185 111.929 20.0857C110.947 19.0528 110.455 17.603 110.455 15.7364C110.455 13.892 110.947 12.4542 111.929 11.4214C112.913 10.3885 114.187 9.87209 115.755 9.87209C117.321 9.87209 118.596 10.3885 119.578 11.4214C120.562 12.4525 121.052 13.892 121.052 15.7364Z" css={normallyWhite} />
					{/* 1 */}
					<path d="M130.837 4.64949V4.6392L127.107 5.63259V10.8535L128.966 10.3576V10.6973V13.2366V18.3013V19.6396V26.7666H128.977H130.837H135.17V3.50684L130.837 4.64949Z" css={normallyWhite} />
					{/* . */}
					<path d="M145.999 21.7002H137.029V26.765H145.999V21.7002Z" css={normallyAccent} />
					{/* + */}
					<path d="M146 3.54636H142.469V0H140.563V3.54636H137.029V5.46279H140.563V9.01087H142.469V5.46279H146V3.54636Z" css={normallyWhite} />
				</svg>
			</div>;
  if (size === `SMALL`) return <svg width="146" height="27" viewBox="0 0 146 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="group" role="img" aria-labelledby="A101-logo">
				<title id="A101-logo">Alcohol101+ logo</title>
				{/* A */}
				<path d="M13.4822 5.41943H6.96061L0 25.7839H5.98884L7.05424 22.0506H13.4192L14.4539 25.7839H20.3798L13.4822 5.41943ZM8.21488 17.9155L9.59344 13.04C9.94858 11.704 10.1471 10.87 10.1891 10.5411H10.315C10.3989 11.0971 10.5862 11.92 10.88 13.0098L12.2586 17.9155H8.21488Z" css={normallyAccent} />
				{/* 1 */}
				<path d="M47.989 7.99484C46.0761 6.11233 43.574 5.17188 40.4795 5.17188C37.385 5.17188 34.8829 6.11392 32.9701 7.99484C31.0572 9.87735 30.1016 12.4017 30.1016 15.5709C30.1016 18.7593 31.0572 21.2947 32.9701 23.1772C34.8829 25.0597 37.385 26.0002 40.4795 26.0002C43.5724 26.0002 46.0761 25.0597 47.989 23.1772C49.9019 21.2947 50.8575 18.7593 50.8575 15.5709C50.8575 12.4032 49.9019 9.87735 47.989 7.99484ZM45.4127 15.5709C45.4127 17.2978 44.9477 18.6401 44.0196 19.5981C43.0898 20.5544 41.8855 21.0326 40.4053 21.0326C38.9234 21.0326 37.7192 20.5544 36.7894 19.5981C35.8612 18.6417 35.3963 17.2993 35.3963 15.5709C35.3963 13.8632 35.8612 12.5319 36.7894 11.5756C37.7192 10.6192 38.9234 10.1411 40.4053 10.1411C41.8855 10.1411 43.0914 10.6192 44.0196 11.5756C44.9494 12.5303 45.4127 13.8632 45.4127 15.5709Z" css={normallyWhite} />
				{/* 0 */}
				<path d="M23.8204 5.30509V5.29555L20.2949 6.21695V11.0511L22.0528 10.592V10.9065V13.2577V17.9473V19.1848V25.7839H22.0641H23.822H27.9174V4.24707L23.8204 5.30509Z" css={normallyWhite} />
				{/* 1 */}
				<path d="M54.6622 5.30509V5.29555L51.1367 6.21536V11.0495L52.893 10.5904V10.9049V13.2561V17.9457V19.1848V25.7839H52.9043H54.6622H58.7576V4.24707L54.6622 5.30509Z" css={normallyWhite} />
				{/* + */}
				<path d="M68.9933 4.28366H65.655V1H63.8535V4.28366H60.5137V6.05814H63.8535V9.3434H65.655V6.05814H68.9933V4.28366Z" css={normallyWhite} />
			</svg>;
  return <></>;
};
export default Logo;